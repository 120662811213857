@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  display: block;
}

button:focus,
button:active {
  outline: none;
}

@layer base {
  select option:not(:first-child) {
    color: black;
  }

  .twitter-card {
    @apply shadow-xl;
    @apply rounded-xl;
    @apply mx-auto !important;
    @apply my-0 !important;
  }

  .background-overlay-4 {
    background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  .background-overlay-6 {
    background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  }

  .background-overlay-7 {
    background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  }

  .background-overlay-8 {
    background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  }

  .image-container {
    width: 100%;
  }

  .image-container > div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }

  .ti-admin-gradient {
    background-color: #2c3e4e;
    background-image: linear-gradient(0deg, #2c3e4e 0%, #2c3e4e 74%);
  }
}

@layer components {
  .gallerycss {
    display: grid;
    grid-gap: 15px;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    scroll-snap-type: x mandatory;
    overflow: auto;
  }
  .slidecss {
    scroll-snap-align: center;
  }
  .slidecss:last-child {
    position: relative;
  }
  .slidecss:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
  }

  .pagination {
    display: flex;
    list-style: none;
    cursor: pointer;
  }

  .pagination a {
    padding: 3px 6px;
    border: 1px solid #2c3e4e;
    color: #2c3e4e;
    margin: 0 2px;
    font-size: 12px;
    background-color: white;
  }

  .pagination__link {
    font-weight: bold;
  }

  .pagination__link--active a {
    color: #fff;
    background: #2c3e4e;
  }

  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
    background-color: white;
  }

  /* dotted list */
  .dotted li::before {
    content: '\2022';
    color: #cccc66;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  /* end dotted list */

  /* ti button */

  .ti-button {
    @apply bg-ti-green;
    @apply text-black;
    @apply font-semibold;
    @apply rounded;
    @apply py-2;
    @apply px-7;
    @apply text-sm;
    @apply shadow-sm;
    @apply text-center;
    @apply cursor-pointer;
  }

  .ti-button:focus,
  .ti-button:active {
    outline: none;
  }

  .ti-button:disabled {
    @apply bg-ti-grey;
    @apply cursor-default;
    @apply text-ti-dim-grey;
  }

  @media (hover: hover) {
    .ti-button:hover {
      @apply bg-ti-green-hover;
    }

    .ti-button:disabled:hover {
      @apply bg-ti-grey;
    }
  }
  /* end ti button */

  /* content wrapper */

  .content-wrapper {
    @apply m-auto;
    @apply px-20;
    @apply py-24;
    max-width: 1440px;
    position: relative;
  }

  @media only screen and (max-width: 960px) {
    .content-wrapper {
      @apply py-20;
    }
  }

  @media only screen and (max-width: 600px) {
    .content-wrapper {
      @apply p-10;
    }
  }
  /* end content wrapper */

  /* react-slick */
  .slick-initialized .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
    height: auto;
  }

  .active-hero-pagination-dot {
    transition: 8s ease-out !important;
    background-position: left;
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-slide > div:first-of-type {
    height: 100% !important;
  }

  .hero-pagination-dot {
    background: linear-gradient(to left, #999999 50%, rgb(239 68 68) 50%) right;
    background-size: 200%;
  }

  /* end react-slick */
  .modal--invisible {
    opacity: 0;
    pointer-events: none; /* Makes the modal unclickable */
  }

  .modal--visible {
    opacity: 1; /* Modal is visible */
    pointer-events: initial; /* Modal is clickable */
  }
  /* nav */

  .sub-nav {
    top: 60px;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  .active-sub-nav {
    border-top: 1px solid #9fa3a6;
    max-height: 100vh;
    visibility: visible;
    -webkit-transition: 0.8s ease-in-out 0s;
    -moz-transition: 0.8s ease-in-out 0s;
    -o-transition: 0.8s ease-in-out 0s;
    transition: 0.8s ease-in-out 0s;
  }

  .active-sub-layer2-underline::after,
  .active-layer1::after {
    position: absolute;
    height: 2px;
    margin: 0 auto;
    content: '';
    left: 0;
    right: 0;
    width: 50%;
    background-color: #cccc66;
    bottom: 10px;
  }

  .active-sub-layer2-underline::after {
    width: 60%;
  }

  .active-layer1::after {
    width: 80%;
  }

  /* end nav */

  .menu-pointer-top {
    position: relative;
  }

  .menu-pointer-top:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 18px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    margin-left: -18px;
    margin-top: -18px;
  }

  .menu-pointer-right {
    position: relative;
  }

  .menu-pointer-right:after {
    content: '';
    position: absolute;
    right: 0;
    top: 15%;
    width: 0;
    height: 0;
    border: 18px solid transparent;
    border-left-color: white;
    border-right: 0;
    margin-top: -18px;
    margin-right: -18px;
  }
}

@layer utilities {
  .min-h-viewport {
    min-height: calc(100vh - 60px);
  }

  .max-h-viewport {
    max-height: calc(100vh - 60px);
  }

  .background-gradient-40 {
    margin-top: -2px;
    background: linear-gradient(#1a1a1a 40%, #f7f7f7 40%);
  }

  .background-gradient-20 {
    background: linear-gradient(-15deg, #f7f7f7 0%, #f7f7f7 55%, #1a1a1a 55%);
    /* background: linear-gradient(#f0f0f0 20%, #cccc66 20%, #cccc66 23%, #1a1a1a 23%, #1a1a1a 80%, white 80%); */
  }

  .background-gradient-10 {
    background: linear-gradient(#cccc66 0%, #cccc66 3%, #cccc66 3%, #1a1a1a 3%, #1a1a1a 23%, #1a1a1a 80%, white 80%);
  }

  @media only screen and (max-width: 600px) {
    .background-gradient-20 {
      background: linear-gradient(#1a1a1a 45%, #f7f7f7 45%);
    }
    .background-gradient-40 {
      margin-top: -2px;
      background: linear-gradient(#1a1a1a 50%, #f7f7f7 50%);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fade-in {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fade-in {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadein {
    animation: fade-in 1s;
    -moz-animation: fade-in 1s; /* Firefox */
    -webkit-animation: fade-in 1s; /* Safari and Chrome */
    -o-animation: fade-in 1s; /* Opera */
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-moz-keyframes fade-out {
    /* Firefox */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-out {
    /* Safari and Chrome */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-o-keyframes fade-out {
    /* Opera */
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .fadeout {
    animation: fade-out 1s;
    -moz-animation: fade-out 1s; /* Firefox */
    -webkit-animation: fade-out 1s; /* Safari and Chrome */
    -o-animation: fade-out 1s; /* Opera */
  }

  @keyframes fade-in-shadow {
    0% {
      @apply shadow-none;
    }
    100% {
      @apply shadow-xl;
    }
  }
  @-moz-keyframes fade-in-shadow {
    /* Firefox */
    0% {
      @apply shadow-none;
    }
    100% {
      @apply shadow-xl;
    }
  }
  @-webkit-keyframes fade-in-shadow {
    /* Safari and Chrome */
    0% {
      @apply shadow-none;
    }
    100% {
      @apply shadow-xl;
    }
  }
  @-o-keyframes fade-in-shadow {
    /* Opera */
    0% {
      @apply shadow-none;
    }
    100% {
      @apply shadow-xl;
    }
  }
  .fadeinShadow {
    animation: fade-in-shadow 1.5s forwards;
    -moz-animation: fade-in-shadow 1.5s forwards; /* Firefox */
    -webkit-animation: fade-in-shadow 1.5s forwards; /* Safari and Chrome */
    -o-animation: fade-in-shadow 1.5s forwards; /* Opera */
  }

  /* overlay */
  .overlay {
    position: fixed;
    cursor: pointer;
    z-index: 40;
    inset: 0;
    background: rgb(0, 0, 0, 0.3);
    height: 100vh;
    width: 100vw;
  }

  .blurry {
    backdrop-filter: blur(8px);
  }

  .overlay::-webkit-scrollbar {
    width: 0 !important;
  }

  .initial {
    position: initial;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .overflow-revert {
    overflow: revert;
  }
  /* end overlay */

  /* Ripple effect */
  .ripple {
    background-position: center;
    transition: background 0.8s;
  }

  @media (hover: hover) {
    .ripple:hover {
      background: #7a7a25 radial-gradient(circle, transparent 1%, #7a7a25 1%) center/15000%;
    }
  }

  .ripple:active {
    background-color: #cccc66;
    background-size: 100%;
    transition: background 0s;
  }

  .ripple:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }

  .mobile-scroll {
    -webkit-overflow-scrolling: touch;
  }

  /* hovering drone effect */

  .hovering-drone {
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
  }

  .shaking-drone {
    animation: shake 4s infinite alternate;
    -webkit-animation: shake 4s infinite alternate;
  }

  .pop-in {
    -webkit-animation: pop-in 0.5s;
    -moz-animation: pop-in 0.5s;
    -ms-animation: pop-in 0.5s;
  }
  @-webkit-keyframes pop-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.2);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }
  @-moz-keyframes pop-in {
    0% {
      opacity: 0;
      -moz-transform: scale(0.2);
    }
    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }
  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }

  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }

  @-webkit-keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .shine {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    -webkit-box-shadow: inset 0px 0px 0px 2px rgba(35, 216, 248, 0.333);
    -moz-box-shadow: inset 0px 0px 0px 2px rgba(35, 216, 248, 0.333);
    box-shadow: inset 0px 0px 0px 2px rgba(35, 216, 248, 0.333);
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }
}
